
import Vue from 'vue';
import config from '@/config';

export default Vue.extend({
    name: 'historyList',
    components: {},
    data() {
        return {
            loading:true,
            isPrimary:true,
            onReachFlag:true,
            title:'历史沿革',
            isZoomed:false,

            params:{ server:'1',category_id:'8',event_id:'',page:1,limit:9},
            total:0,
            events: [],
            content:"",
          }
    },
    created() {
        this.postUserInfo(this.$route.query.id as string);
    },
    mounted() {
        const scrollingDiv = this.$refs.scrollingDiv as HTMLDivElement;
        const scrollHeight = scrollingDiv.scrollTop;
        scrollingDiv.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        openimg(event) {
            this.content = event.content
            this.isZoomed = !this.isZoomed
        },
        goBack(){
            this.$router.replace({path:'/history'});
        },
        getFullImagePath(relativePath: string) {
            return `${config.domain}${relativePath}`;
        },
        async postUserInfo(id) {
            try {
                this.params.event_id = id
                const result = await this.$api.postList(this.params);
                this.events = [...this.events, ...result.data.list];
                this.total =  result.data.total
                this.loading = false
                this.isPrimary = false
            } catch (error) {
                console.log('error', error)
            }
        },
        handleScroll() {
            const scrollingDiv = this.$refs.scrollingDiv as HTMLDivElement;
            const scrollHeight = scrollingDiv.scrollTop;
            const pageHeight = this.params.page * 100
            console.log(scrollHeight,pageHeight)
            if (scrollHeight > pageHeight){
                // 显示底部提示
                const limitlen = this.total / this.params.limit
                // 判断是否有数据
                if ( this.params.page >= limitlen){
                    this.onReachFlag = false
                    return;
                }else {
                    // console.log("还有数据")
                    this.onReachFlag = true
                }
                this.params.page ++
                this.postUserInfo()
            }
        },
    },
    beforeDestroy() {
        // 移除滚动事件监听，以避免内存泄漏
        window.removeEventListener('scroll', this.handleScroll);
    }
});
